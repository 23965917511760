import IconEmail from "public/assets/sections/home/contacts_home/email.svg"
import IconPhone from "public/assets/sections/home/contacts_home/phone.svg"
import IconLocation from "public/assets/sections/home/contacts_home/location.svg"

export default {
  ua: {
    emails_content: [
      {
        title: "Основний",
        link: "info@epr.com.ua"
      },
      {
        title: "КОМЕРЦІЙНИЙ ВІДДІЛ",
        link: "marketing@epr.com.ua"
      },
      {
        title: "ВІДДІЛ ПОСТАЧАННЯ",
        link: "snab@epr.com.ua"
      },
      {
        title: "ВІДДІЛ БУХГАЛТЕРІЇ",
        link: "buh@epr.com.ua"
      },
      {
        title: "Відділ кадрів",
        link: "ok@epr.com.ua"
      },
    ],
    phones_content: "",
    location: {
      location_content: "49038 вул. Академіка Белелюбського 14, м.Дніпро, Україна",
      map: ""
    }
  },
  ru: {
    emails_content: [
      {
        title: "Основной",
        link: "info@epr.com.ua"
      },
      {
        title: "КОММЕРЧЕСКИЙ ОТДЕЛ",
        link: "marketing@epr.com.ua"
      },
      {
        title: "ОТДЕЛ СНАБЖЕНИЯ",
        link: "snab@epr.com.ua"
      },
      {
        title: "ОТДЕЛ БУХГАЛТЕРИИ",
        link: "buh@epr.com.ua"
      },
      {
        title: "ОТДЕЛ КАДРОВ",
        link: "ok@epr.com.ua"
      },
    ],
    phones_content: "",
    location: {
      location_content: "49038 ул.Академика Белелюбского 14, г.Днепр, Украина",
      map: ""
    }
  },
  en: {
    emails_content: [
      {
        title: "General",
        link: "info@epr.com.ua"
      },
      {
        title: "SALES DEPARTMENT",
        link: "marketing@epr.com.ua"
      },
      {
        title: "PURCHASE DEPARTMENT",
        link: "snab@epr.com.ua"
      },
      {
        title: "ACCOUNTING DEPARTMENT",
        link: "buh@epr.com.ua"
      },
      {
        title: "RESOURCES DEPARTMENT",
        link: "ok@epr.com.ua"
      },
    ],
    phones_content: "",
    location: {
      location_content: "49038, 14 Academician Belelubsky street, Dnipro Ukraine",
      map: ""
    }
  },
  iconEmail: <IconEmail />,
  iconPhone: <IconPhone />,
  iconLocation: <IconLocation />,
  phone_1: "+38 097 089 45 54",
  phone_2: "+38 099 150 55 01",
}