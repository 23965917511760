import { useRouter } from "next/router";
import Link from "next/link";
import ContactsHomeLocales from "./locales/data"

const ContactsHome = () => {
  const { locale } = useRouter();

  return (
    <div className="w-full grid grid-cols-1 xl:grid-cols-3 gap-2 smooth-transition">

      <div className="flex flex-row w-full xl:w-[400px] py-4 px-2">
        <div>
          <p className="w-20 h-20">
            {ContactsHomeLocales.iconEmail || <div>No icon</div>}
          </p>
        </div>
        <div className="pl-4 pt-2">
          {ContactsHomeLocales[locale].emails_content.map((email, index, array) => (
            <div key={email.link} className={`${index !== array.length - 1 ? "pb-4" : ""}`}>
              <p className="uppercase font-medium p-0 text-sm">{email.title}</p>
              <Link href={`mailto:${email.link}`}>
                <a className="menu-link-out-hover font-medium text-sm minmax-s:text-lg md:text-xl">{email.link}</a>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col w-full xl:w-[400px] py-4 px-2">
        <div className="flex flex-row w-full">
          <div>
            <p className="w-20 h-20">
              {ContactsHomeLocales.iconPhone || <div>No icon</div>}
            </p>
          </div>
          <div className="pl-4">
            <p className="pb-0">
              <Link href={`tel:${ContactsHomeLocales.phone_1}`}>
                <a className="text-sm minmax-s:text-lg md:text-xl menu-link-out-hover font-medium">{ContactsHomeLocales.phone_1}</a>
              </Link>
            </p>
            <p className="p-0">
              <Link href={`tel:${ContactsHomeLocales.phone_2}`}>
                <a className="text-sm minmax-s:text-lg md:text-xl menu-link-out-hover font-medium">{ContactsHomeLocales.phone_2}</a>
              </Link>
            </p>
          </div>
        </div>

        <div className="flex flex-row w-full mt-8">
          <div>
            <p className="w-20 h-20">
              {ContactsHomeLocales.iconLocation || <div>No icon</div>}
            </p>
          </div>
          <div className="pl-4">
            <p className="font-medium text-sm minmax-s:text-lg md:text-xl">
              {ContactsHomeLocales[locale].location.location_content}
            </p>
          </div>
        </div>
      </div>

      <div className="h-80 w-full xl:w-[400px] py-4 px-2">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5289.486338853105!2d35.011660138564295!3d48.48063974227041!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dbe25ae2491941%3A0x8d368165f3bbdd65!2z0J7QntCeINCf0JrQpCAi0K3Qu9C10LrRgtGA0L7Qv9GA0L7QvNGA0LXQvNC-0L3RgiI!5e0!3m2!1sru!2sua!4v1638967920105!5m2!1sru!2sua"
          width="100%"
          height="100%"
          title="Google Map"
          allowFullScreen
          loading="lazy"
        />
      </div>

    </div>
  );
};

export default ContactsHome;